.App {
  text-align: center;

  padding:0 !important;
}

.App-logo {
  pointer-events: none;
  top:0;
}

.App-header {
  position: fixed;
  top: 20px;
  left: 20px;
  min-height: 2vh;
  display: flex;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 10;
}

.App-link {
  color: #61dafb;
}
.video_contain {
    position: fixed;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    min-height: 200%;
    transition-property: height, min-height;
    transition-duration: .6s;
    transition-delay: .1s;
    transition-timing-function: ease-in;
    overflow: hidden;
}
#myVideo {
  position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    min-height: 50%;
    min-width: 50%;
    overflow: hidden;
}
video::-webkit-media-controls-mute-button {
    position: fixed;
    top: 0;
    right: 80px;
    display: block !important;
    visibility: visible !important;
}
video::-webkit-media-controls-panel {
    display: flex !important;
    opacity: 1 !important;
    visibility: visible !important;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
