::-webkit-scrollbar {
    display: none;
}
.navbar{
    position: fixed;
    top: calc(100vh - 50px);
    top: calc(var(--vh, 1vh) * 100 - 50px);
    left:0;
    right:0;
    z-index: 1000;
    padding: 0 !important;
    box-shadow: 0px -5px rgba(0,0,0,0.1);
    overflow: hidden!important;
    height: 50px !important;
}
.flex-grid-thirds {
  display: flex;
  width: 100vw;
  justify-content: space-between;
}
.flex-grid-thirds:nth-child(1) .col {
  width: 32%;
  height: 50px;
  color:black;
  /* background-color: #092B61; */
  background-color: white;
}
#flags {
    position: fixed;
    top:0;
    width: 80px;
    z-index: 100;
}
#us, #you, #bike, #routes {
    height: 50px !important;
    line-height: 30px;
    padding: 10px;

}
#us li, #you li, #bike li,#routes li {
    display: inline-block;
}
#us svg, #you svg, #bike svg,#routes svg {
    margin-right: 20px;
    margin-bottom: 5px;
}
#us:hover, #you:hover, #bike:hover, #routes:hover{
    background-color: #154879;
    color: white;
}
@media only screen and (max-width: 600px){
    #us li p, #you li p, #bike li p,#routes li p{
        display: none;
    }
}

.wrapper-dropdown-5 {
    /* Size & position */
    position: relative;
    width: 60px;
    margin: 10px auto;
    padding: 8px 8px;
    margin-left: calc(100vw - 70px);
    /* Styles */
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 0 rgba(0,0,0,0.2);
    cursor: pointer;
    outline: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}


.wrapper-dropdown-5:after { /* Little arrow */
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -3px;
    border-width: 6px 6px 0 6px;
    border-style: solid;
    border-color: black transparent;
    left:40px;
}

.wrapper-dropdown-5 .dropdown {
    /* Size & position */
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    /* Styles */
    background: #fff;
    border-radius: 0 0 5px 5px;
    border: 1px solid rgba(0,0,0,0.2);
    border-top: none;
    border-bottom: none;
    list-style: none;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;

    /* Hiding */
    max-height: 0;
    overflow: hidden;
}

.rodal-close {
    position: fixed !important;
    right: 90px !important;
    top:25px !important;
    z-index: 200000;
}
.wrapper-dropdown-5 .dropdown li a {
    display: block;
    text-decoration: none;
    color: #333;
    padding: 10px 0;
    transition: all 0.3s ease-out;
    border-bottom: 1px solid #e6e8ea;
    margin-left: -40px;
}

.wrapper-dropdown-5 .dropdown li:last-of-type a {
    border: none;
}

.wrapper-dropdown-5 .dropdown li i {
    margin-right: 5px;
    color: inherit;

}

/* Hover state */

.wrapper-dropdown-5 .dropdown li:hover a {
    color: #57a9d9;
}

/* Active state */

.wrapper-dropdown-5.active {
    border-radius: 5px 5px 0 0;
    background: #092B61;
    box-shadow: none;
    border-bottom: none;
    color: white;
}

.wrapper-dropdown-5.active:after {
    border-color: white transparent;
}

.wrapper-dropdown-5.active .dropdown {
    border-bottom: 1px solid rgba(0,0,0,0.2);
    max-height: 400px;
}
.modalContent {
    position: relative;
    overflow: scroll;
    height: calc(100vh - 50px);
    height: calc(var(--vh, 1vh) * 100 - 50px);
}
.usOverline{
    position: relative;
    padding: 10px;

    margin-bottom: 80px;
    margin-top: 100px;
}


.timeline {
  list-style-type: none;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

}

/* item left aligned default */
.tl-item {
  position: relative;
  display: block;
  min-height: 160px;
  width: 50%;
  float: left;
  text-align: right;
  border-right: 10px solid #154879;
  -webkit-box-sizing: border-box;
  margin-bottom: 40px;
  clear: left;
}

.tl-icon {
  float: right;
  border: 1px solid #154879;
  background: #154879;
  width: 50px; height: 50px;
  border-radius: 50%;
  margin-right: -32px;
  margin-top: 0;
  padding: 11px;
}

/* padded content  */
.tl-content {
  position: relative;
  padding: 20px 40px;
  margin-right: 40px;
}

.tl-time {
  text-align: right;
  margin-right: 40px;
  margin-top: 10px;
}
@media only screen and (max-width: 600px)  {
    .tl-item {
        width: 100%;
        float: left;
        text-align: left;
        border-right: 0;
        border-left: 10px solid #154879;
        left: -10px;
        margin-bottom: -100px;
        clear: right;
        padding-bottom: 40px;
    }
    .tl-item .tl-icon {
      float: left;
      margin-left: -30px;
    }

    .tl-item .tl-content {
      margin-right: 0;
      margin-left: 40px;
    }

    .tl-item .tl-time {
      text-align: left;
      margin-left: 40px;
    }
}

/* item right aligned */
.tl-right {
  float: right;
  text-align: left;
  margin-top: 70px;
  border-right: 0;
  border-left: 10px solid #154879;
  left: -10px;
  margin-bottom: 0;
  clear: right;
  padding-bottom: 40px;
}

.tl-right .tl-icon {
  float: left;
  margin-left: -30px;
  padding-right: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 14px !important;
}

.tl-right .tl-content {
  margin-right: 0;
  margin-left: 40px;
}

.tl-right .tl-time {
  text-align: left;
  margin-left: 40px;
}
.bike {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 10px;
    bottom: 0;
    left: 600px;
    right: 0;
    margin: auto;
}
.bikeDescription {
    padding: 10px;
    width: 50%;
    position: fixed;
    overflow: scroll;
    top: -20px;
    left:10px;
    background-color: #F5F5F5;
    z-index: 100;
    font-size: 0.8rem;
    box-shadow: 5px 10px rgba(0,0,0,0.3);
    padding-bottom: 0px;
}
@media only screen and (max-width: 1250px)  {
    .bike {
        left: 400px;
    }
}

@media only screen and (max-width: 1024px)  {
    .bikeDescription {
        display: block;
        overflow: hidden !important;
        position: relative !important;
        width: 100% !important;
        top: 0px;
        padding-bottom: 100px;
    }
    .bike {
        display: block;
        position: relative;
        max-width:1512px;
        max-height: 500px;
        width:auto ;
        height: auto;
        left:0;
        top: 0;

    }
    .rodal-dialog {
        padding: 0px !important;
        
        overflow: scroll;
    }
    #bikeContainer {
        overflow: scroll;
    }
    #quoteContent {
    	font-size: 1rem!important;
    	line-height: 1.5;
    }
    .quoteContainer {
    	margin: 0 auto;
    	max-width: 90%;
    	padding: 15% 10% 0!important;
    }
    #routesBar {
        margin-top: 60px!important;
    }
    #routesBar li button {
        font-size: 1rem !important;
    }

}

.bikeDescription h1 {
    width: 100%;
    padding: 20px;
    font-size: 2rem;
    text-align: left;
    margin-top: 10px;
}
.bikeDescription ul {
    position: relative;
    padding: 20px;
    list-style:none;
}
.bikeDescription ul li {
    line-height: 20px;
    text-align: left;

}
#bikeContainer {
    width: 100%;
    height: 100% ;
    line-height: 115px;
    position: relative;
    overflow: scroll;
    overflow-x: hidden;
    text-align: center;
}
.quoteContainer {
	margin: 0 auto;
	max-width: 90%;
	padding: 5% 10% 0;
}
.quoteContainer:last-child {
	padding-bottom: 100px;
}
#quote {
	background-color:#F5F5F5;
	padding: 5% 10%;
    box-shadow: 5px 10px rgba(0,0,0,0.3);
}
#quoteContent {
	font-size: 1.5rem;
	line-height: 1.5;
    text-align: left;
    font-style: italic;
    white-space: pre-line;
}
#quoteAuthor {
	font-weight: 600;
	letter-spacing: 2px;
	text-align: right;
	text-transform: uppercase;
    white-space: pre-line;
}
#quoteAuthor:before {
	content: " "
}
#routesBar {
    width: 100%;
    padding: 0;
    margin-top: 10px;
}
#routesBar li {
    display: inline-block;
    width: 100%;
    background-color: red;
}
#routesBar li button {
    border: none;
    font-size: 1.3rem;
    width: 100%;
    padding: 10px;
    color:white;
}
#routesBar li:nth-child(1) button {
    background-color: #494331;
}
#routesBar li:nth-child(2) button {
    background-color: #D4D4AA;
}
#routesBar li:nth-child(3) button {
    background-color: #48BF84;
}
.tl-itemBike {
    width: 100%;
    float: left;
    text-align: left;
    border-right: 0;
    border-left: 10px solid #154879;
    left: -10px;
    margin-bottom: -100px;
    clear: right;
    padding-bottom: 40px;
}
.tl-itemBike .tl-iconBike {
    border: 1px solid #154879;
    background: #154879;
    width: 50px; height: 50px;
    border-radius: 50%;
    margin-right: -32px;
    margin-top: 0;
    padding: 11px;
    float: left;
    margin-left: -30px;
}

.tl-itemBike .tl-contentBike {
    position: relative;
    padding: 5px 10px;
    margin-right: 0;
    margin-left: 35px;
}

.tl-itemBike .tl-timeBike {

    margin-right: 40px;
    margin-top: 10px;
    text-align: left;
    margin-left: 40px;
}
.leftButton, .rightButton {
    border: none;
    width: 200px;
    background-color: white;
}
